<template>
    <AppLayout>
        <RequireLogin>
            <Snackbar :attr="snackbar" />
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">Add realm</h1>
                </v-col>
            </v-row>
            <template v-if="isWordpress">
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <p>To add an authentication realm for WordPress, start by installing the LoginShield for Wordpress plugin in the WordPress site.</p>
                    <p>The authentication realm will be added with a 14-day free trial. Then you can return to <router-link :to="{ path: '/account', query: { acct: $route.query.acct } }">account settings</router-link> to select a pricing plan for the authentication realm.</p>
                    <p>To learn more: <TextLink href="/handbook/applications/wordpress/admin-guide/install/" target="_blank">How to install LoginShield for WordPress</TextLink>.</p>
                </v-col>
            </v-row>
            </template>
            <template v-if="!isWordpress">
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <p>Please contact <TextLink href="/contact/?topic=support">customer support</TextLink> to add an enterprise authentication realm.</p>
                    <v-alert color="blue" dark>
                        <v-row align="center">
                            <v-col class="shrink">
                                <font-awesome-icon :icon="['fas', 'info-circle']" size="1x"></font-awesome-icon>
                            </v-col>
                            <v-col class="grow">
                                <span>Using WordPress? You can start right now.</span>
                            </v-col>
                            <v-col class="shrink">
                                <v-btn text @click="isWordpress = true">Continue</v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-col>
            </v-row>
            </template>
        </RequireLogin>
    </AppLayout>
</template>
<script>
// import { mapState, mapGetters } from 'vuex';
import AppLayout from '@/components/AppLayout.vue';
import RequireLogin from '@/components/RequireLogin.vue';
import Snackbar from '@/components/Snackbar.vue';
import TextLink from '@/components/TextLink.vue';

/*
                if (isAuthenticated && !error && !isRegisterQuickUserPending && accountList.length === 0 && productTag) {
                    // productTag could be 'wordpress'
                    let prefix = process.env.VUE_APP_WEBSITE_PATH ?? '';
                    if (prefix.endsWith('/')) {
                        prefix = prefix.substr(0, prefix.length - 1);
                    }
                    const nextRoute = `${prefix}/{this.$route.path}`;
                    // from=register indicates the pricing page should return the user to this registration
                    window.location.href = `/pricing/${encodeURIComponent(productTag)}/?nextRoute=${encodeURIComponent(nextRoute)}&i=${encodeURIComponent(this.interactionId)}`;
                }
*/
/* TODO:  support optional parameter planId  to pre-select the plan */
export default {
    components: {
        AppLayout,
        RequireLogin,
        Snackbar,
        TextLink,
    },
    data() {
        return {
            isWordpress: false,
        };
    },
    mounted() {
        if (this.$route.query.tag === 'wordpress') {
            this.isWordpress = true;
        }
    },
};
</script>
